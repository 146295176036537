import { LoaderFunctionArgs, json } from "@remix-run/node";
import { ClientLoaderFunctionArgs, useLoaderData } from "@remix-run/react";
import { HtmlRenderer } from "~/components/atoms/html-renderer";
import { ApiClient, createApiClient } from "~/services/api";

const fetchData = async (api: ApiClient, slug: string) => {
  const page = await api.getPageBySlug(slug);

  if (!page) {
    throw new Response(null, {
      status: 404,
      statusText: "Not Found",
    });
  }

  return { page };
};

export const loader = async (args: LoaderFunctionArgs) => {
  const slug = new URL(args.request.url).pathname;
  const api = createApiClient();
  const data = await fetchData(api, slug);
  return json(data);
};

export const clientLoader = async (args: ClientLoaderFunctionArgs) => {
  const slug = new URL(args.request.url).pathname;
  const api = createApiClient();
  const data = await fetchData(api, slug);
  return data;
};

export default function Route() {
  const { page } = useLoaderData<typeof loader>();
  return (
    <>
      <h1 className="text-4xl font-medium text-white md:text-5xl bg-neutral-900">
        <div className="container py-16">{page.title}</div>
      </h1>
      <div className="container">
        <HtmlRenderer html={page.content} />
      </div>
    </>
  );
}
